import React from 'react';
import Logo from './logo';
const Landing = () => {
	return (
		<div className='landing-logo'>
			<Logo />
			<div className='landing-mid-panel'>
				<div className='presentation'>
					<h2>En tant que développeur indépendant spécialisé dans <div className='javascript'>JavaScript</div> et <div className='typescript'>TypeScript</div>, je mets en œuvre ma passion pour créer des solutions innovantes et interactives.</h2>
				</div>
				<div className='presentation-2'>
					<h2>Créatif</h2>
					<h2>Précis</h2>
					<h2>Efficace</h2>
				</div>
			</div>
			<div className='landing-links'>
				<a href="https://discord.gg/hgJqNsVB9R" id='fir'>Discord</a>
				<a href="https://github.com/Oxyrobit/" id='sec'>Github</a>
				<a href="mailto:contact@oxyrobot.fr" id='thi'>Contact</a>
			</div>
		</div>
	);
}

export default Landing;
