import './App.scss';
import Landing from './components/landing';
import React from 'react';

function App() {
    return ( 
        <div id='App'>
            <Landing />
        </div>
    );
}

export default App;